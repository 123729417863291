import React, { useState, useEffect } from "react";

import useHttp from "hooks/use-http";

const CartContext = React.createContext({
  cartID: "",
  cartTotal: 0,
  cartElements: [],

  sendCartID: () => {},
  sendCartElements: () => {},
  sendCartTotal: () => {},
  removeCartElement: () => {},

  cleanCart: () => {},
});

export const CartContextProvider = ({ children }) => {
  const { sendRequest: sendCartRequest } = useHttp();
  const initialCart = localStorage.getItem("cartID");
  const initialSecret = localStorage.getItem("cartSecret");
  const [cartID, setCartID] = useState(initialCart);
  const [cartElements, setCartElements] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [cartSecret, setCartSecret] = useState(initialSecret);
  const [reload, setReload] = useState(0);
  const [fastView, setFastView] = useState(0);

  const cartFastView = () => setFastView((old) => old + 1);
  const handleReload = () => setReload((old) => old + 1);

  const cartSecretHandler = (val) => {
    setCartSecret(val);
    localStorage.setItem("cartSecret", val);
  };

  const cartIDHandler = (val) => {
    localStorage.setItem("cartID", val);
    setCartID(val);
  };

  const cartElementsHandler = (val) => {
    setCartElements(val);
  };

  const cartTotalHandler = (val) => {
    setCartTotal(val);
  };

  const cleanCartHandler = () => {
    setCartID("");
    setCartSecret("");
    setCartElements([]);
    // alert("STO SVUOTANDO IL CARRELLO -VERSIONE DEMO");
  };

  const manageCartData = ({ secret, id }) => {
    cartSecretHandler(secret);
    cartIDHandler(id);
  };
  useEffect(() => {
    if (!cartID) {
      sendCartRequest({
        url: "/cart",
        methodH: "POST",
        manageData: (e) => {
          manageCartData(e.data);
        },
      });
    }
  }, [cartID]);

  const contextValue = {
    cartTotal,
    cartID,
    cartElements,
    sendCartID: cartIDHandler,
    sendCartElements: cartElementsHandler,
    sendCartTotal: cartTotalHandler,
    cleanCart: cleanCartHandler,
    cartSecret,
    reload,
    handleReload,
    fastView,
    cartFastView,
    elemeniCart: cartElements,
  };

  return <CartContext.Provider value={contextValue}>{children}</CartContext.Provider>;
};
export default CartContext;
