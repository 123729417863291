/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/**
  All of the routes for the Material Kit 2 PRO React React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Navbar.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `name` key is used for the name of the route on the Navbar.
  2. The `icon` key is used for the icon of the route on the Navbar.
  3. The `collapse` key is used for making a collapsible item on the Navbar that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  4. The `route` key is used to store the route location which is used for the react router.
  5. The `href` key is used to store the external links location.
  6. The `component` key is used to store the component of its route.
  7. The `dropdown` key is used to define that the item should open a dropdown for its collapse items .
  8. The `description` key is used to define the description of
          a route under its name.
  9. The `columns` key is used to define that how the content should look inside the dropdown menu as columns,
          you can set the columns amount based on this key.
  10. The `rowsPerColumn` key is used to define that how many rows should be in a column.
*/
import { lazy } from "react";

// @mui material components
import Icon from "@mui/material/Icon";

// Pages
// import Pricing from "layouts/pages/company/pricing";
// import HelpCenter from "layouts/pages/support/help-center";
// import ContactUs from "layouts/pages/support/contact-us";
// import Faq from "layouts/pages/support/faq";
// import Privacy from "layouts/pages/support/privacy";
// import DesktopApp from "layouts/pages/apps/desktop-app";
// import SingleArticle from "layouts/pages/blogs/single-article";
// import Author from "layouts/pages/blogs/author";
// import VirtualRealityPage from "layouts/pages/extra/virtual-reality";

import EmailConfirm from "pagensp/EmailConfirm/EmailConfirm";

// // Account
// import SignInBasicPage from "layouts/authentication/sign-in/basic";
// import SignInCoverPage from "layouts/authentication/sign-in/cover";
// import SignInIllustration from "layouts/authentication/sign-in/illustration";
// import SignInSimplePage from "layouts/authentication/sign-in/simple";
// import SignUpCoverPage from "layouts/authentication/sign-up/cover";
// import ResetPasswordPage from "layouts/authentication/reset-password/cover";

// // Sections
// import PageHeaders from "layouts/sections/page-sections/page-headers";
// import Features from "layouts/sections/page-sections/featuers";
// import PricingSection from "layouts/sections/page-sections/pricing";
// import FaqSection from "layouts/sections/page-sections/faq";
// import BlogPosts from "layouts/sections/page-sections/blog-posts";
// import Testimonials from "layouts/sections/page-sections/testimonials";
// import Teams from "layouts/sections/page-sections/teams";
// import Stats from "layouts/sections/page-sections/stats";
// import Cta from "layouts/sections/page-sections/cta";
// import Applications from "layouts/sections/page-sections/applications";
// import LogoAreas from "layouts/sections/page-sections/logo-areas";
// import Footers from "layouts/sections/page-sections/footers";
// import GeneralCards from "layouts/sections/page-sections/general-cards";
// import ContentSections from "layouts/sections/page-sections/content-sections";
// import Navbars from "layouts/sections/navigation/navbars";
// import NavTabs from "layouts/sections/navigation/nav-tabs";
// import Pagination from "layouts/sections/navigation/pagination";
// import Newsletters from "layouts/sections/input-areas/newsletters";
// import ContactSections from "layouts/sections/input-areas/contact-sections";
// import Inputs from "layouts/sections/input-areas/inputs";
// import Forms from "layouts/sections/input-areas/forms";
// import Alerts from "layouts/sections/attention-catchers/alerts";
// import Notifications from "layouts/sections/attention-catchers/notifications";
// import Modals from "layouts/sections/attention-catchers/modals";
// import TooltipsPopovers from "layouts/sections/attention-catchers/tooltips-popovers";
// import Avatars from "layouts/sections/elements/avatars";
// import Badges from "layouts/sections/elements/badges";
// import BreadcrumbsEl from "layouts/sections/elements/breadcrumbs";
// import Buttons from "layouts/sections/elements/buttons";
// import Dropdowns from "layouts/sections/elements/dropdowns";
// import ProgressBars from "layouts/sections/elements/progress-bars";
// import SocialButtons from "layouts/sections/elements/social-buttons";
// import Tables from "layouts/sections/elements/tables";
// import Toggles from "layouts/sections/elements/toggles";
// import Typography from "layouts/sections/elements/typography";

const HomePage = lazy(() => import("pagensp/HomePage/HomePage"));
const NotFound = lazy(() => import("pagensp/NotFound/NotFound"));
const ChiSiamo = lazy(() => import("pagensp/ChiSiamo/ChiSiamo"));
const PerLeAziende = lazy(() => import("pagensp/PerLeAziende/PerLeAziende"));
const CategoryLegale = lazy(() => import("pagensp/CategoryLegale/CategoryLegale"));
const CategoryPatronato = lazy(() => import("pagensp/CategoryPatronato/CategoryPatronato"));
const CategoryColfBadanti = lazy(() => import("pagensp/CategoryColfBadanti/CategoryColfBadanti"));
const CategoryImmigrazione = lazy(() =>
  import("pagensp/CategoryImmigrazione/CategoryImmigrazione")
);
const CategoryLavoro = lazy(() => import("pagensp/CategoryLavoro/CategoryLavoro"));

const CartPage = lazy(() => import("pagensp/CartPage/CartPage"));
const CheckoutDatiPersonali = lazy(() => import("pagensp/Checkout/CheckoutDatiPersonali"));

// import Dashboard from "Dashboard/Dashboard";
const Test = lazy(() => import("pages/Test/test"));
const ModalLogReg = lazy(() => import("pagensp/Authentication/ModalLogReg"));

const CoomingSoon = lazy(() => import("pagensp/CoomingSoon/CoomingSoon"));

const Blog = lazy(() => import("pagensp/Blog"));
const BlogArticle = lazy(() => import("pagensp/Blog/BlogArticle"));
const BlogCategory = lazy(() => import("pagensp/Blog/BlogCategory"));
const Servizi = lazy(() => import("pagensp/Servizi"));
const PerIConsulenti = lazy(() => import("pagensp/PerIConsulenti"));
const LavoraConNoi = lazy(() => import("pagensp/LavoraConNoi"));
const NonsolopatronatoWewelfare = lazy(() => import("pagensp/NonsolopatronatoWewelfare"));
const Contatti = lazy(() => import("pagensp/Contatti"));
const ComeFunziona = lazy(() => import("pagensp/ComeFunziona"));

const CategoryCaf = lazy(() => import("pagensp/CategoryCaf/CategoryCaf"));
const SingleProduct = lazy(() => import("pagensp/SingleProduct/SingleProduct"));
const Dashboard = lazy(() => import("Dashboard/Dashboard"));

const PrivacyPolicy = lazy(() => import("pagensp/PrivacyPolicy"));
const TerminiCondizioni = lazy(() => import("pagensp/TerminiCondizioni"));
const CookiePolicy = lazy(() => import("pagensp/CookiePolicy"));

const routes = [
  { name: "Come Funziona", route: "/come-funziona", component: <ComeFunziona /> },
  { name: "Contatti", route: "/contatti", component: <Contatti /> },
  { name: "Cooming Soon", route: "/coomingsoon", component: <CoomingSoon /> },
  { name: "Chi Siamo", route: "/chi-siamo", component: <ChiSiamo /> },
  { name: "Servizi", route: "/servizi", component: <Servizi /> },
  { name: "Per le Aziende", route: "/per-le-aziende", component: <PerLeAziende /> },
  { name: "Blog", route: "/news", component: <Blog /> },
  {
    name: "blogs",
    collapse: [
      {
        name: "blog article",
        route: "/news/:category/:title/:id",
        component: <BlogArticle />,
      },
      {
        name: "blog category",
        route: "/news/:category/:id",
        component: <BlogCategory />,
      },
    ],
  },
  { name: "Per i Consulenti", route: "/per-i-consulenti", component: <PerIConsulenti /> },
  { name: "Lavora con noi", route: "/lavora-con-noi", component: <LavoraConNoi /> },
  {
    name: "Nonsolopatronato diventa Wewelfare",
    route: "/nonsolopatronato-diventa-wewelfare",
    component: <NonsolopatronatoWewelfare />,
  },
  { name: "Not Found", route: "/404", component: <NotFound /> },
  { name: "Email confirm", route: "/emailconfirm", component: <EmailConfirm /> },
  { name: "Authentication", icon: "lock", route: "/authentication", component: <ModalLogReg /> },
  { name: "test", icon: "home", route: "/test", component: <Test /> },
  { name: "dashboard", route: "/dashboard", component: <Dashboard /> },
  { name: "dashboard", route: "/dashboard/:id/:stripe", component: <Dashboard /> },
  { name: "checkout", route: "/checkout", component: <CheckoutDatiPersonali /> },
  { name: "privacypolicy", route: "/privacy-policy", component: <PrivacyPolicy /> },
  { name: "terminiutilizzo", route: "/termini-condizioni", component: <TerminiCondizioni /> },
  { name: "cookiepolicy", route: "/cookie-policy", component: <CookiePolicy /> },
  {
    name: "Wewelfare",
    icon: <Icon>dashboard</Icon>,
    columns: 3,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "Pagine",
        collapse: [
          {
            name: "Homepage",
            route: "/",
            component: <HomePage />,
          },
          {
            name: "Categoria Caf",
            route: "/caf/:id",
            component: <CategoryCaf />,
          },
          {
            name: "Categoria Consulenze Legali",
            route: "/consulenzalegale/:id",
            component: <CategoryLegale />,
          },
          {
            name: "Categoria Patronato",
            route: "/patronato/:id",
            component: <CategoryPatronato />,
          },
          {
            name: "Categoria Colf e Badanti",
            route: "/colfebadanti/:id",
            component: <CategoryColfBadanti />,
          },
          {
            name: "Categoria Immigrazione",
            route: "/immigrazione/:id",
            component: <CategoryImmigrazione />,
          },
          {
            name: "Categoria consulenza sul lavoro",
            route: "/consulenzalavoro/:id",
            component: <CategoryLavoro />,
          },
          {
            name: "Prodotto singolo",
            route: "/product/:category/:title/:id",
            component: <SingleProduct />,
          },
          {
            name: "Carrello",
            route: "/carrello",
            component: <CartPage />,
          },
        ],
      },
    ],
  },
];

export default routes;
