import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import ProductFruits from "react-product-fruits";

import "swiper/swiper.min.css";
import "swiper/modules/navigation/navigation.min.css";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Kit 2 PRO React themes
import theme from "assets/theme";
// import Presentation from "layouts/pages/presentation";

// Material Kit 2 PRO React routes
import routes from "routes";
import "assets/css/GeneralStyle.css";
import "assets/css/CustomStyle.css";

// Animate.css
import "assets/css/animate.min.css";

export default function App() {
  const { pathname } = useLocation();

  const userInfo = {
    username: "salvatore@nonsolopatronato.it",
  };

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "auto" });
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key} />;
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <ProductFruits projectCode="DEDbtZavWwXNVOD3" language="en" {...userInfo} />
      <CssBaseline />
      <Routes>
        {getRoutes(routes)}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}
