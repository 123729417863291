import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

const WrapperHelmet = ({ children, description, keywords, ogImage }) => {
  const [title, setTitle] = useState("");

  useEffect(() => {
    const [, , , titlez] = window.location.href.split("/");
    const titleTemp = `WEWELFARE | ${titlez.toUpperCase()}`;
    setTitle(titleTemp);
  }, [window.location.href]);

  return (
    <>
      <Helmet title={title}>
        <meta charSet="utf-8" />
        <meta name="description" content={description || "WEWELFARE"} />
        <meta name="keywords" content={keywords || ""} />
        <meta property="og:url" content={window.location.href || ""} />
        <meta
          property="og:title"
          content={title || "Servizi di Caf, Patronato e servizi rivolti al cittadino online"}
        />
        <meta property="og:image" content={ogImage || ""} />
        <meta
          property="og:description"
          content={
            description ||
            "Wewelfare.com è il primo intermediario di servizi CAF e Patronato Online. Puoi dire finalmente addio alle file agli sportelli"
          }
        />
        <meta property="og:site_name" content="WEWELFARE" />
        <meta property="og:type" content="article" />
        <link rel="canonical" href={window.location.href || ""} />
        <meta property="og:locale" content="it_IT" />
      </Helmet>
      {children}
    </>
  );
};
export default WrapperHelmet;
