import React, { useEffect, useState } from "react";
import jwtDecode from "jwt-decode";
// eslint disable-next-line import/no-unresolved
import md5 from "md5";

const AuthContext = React.createContext({
  token: "",
  session_token: "",
  profiletype: "",
  configurations: [{}],
  isLoggedIn: false,
  login: () => {},
  setprofile: () => {},
  setidoperatore: () => {},
  setidtouroperator: () => {},
  setslugcampo: () => {},
  logout: () => {},
});

export const AuthContextProvider = ({ children }) => {
  const initialToken = localStorage.getItem("token");
  const initialTypeUser = localStorage.getItem("type_user");
  const initialUserData = JSON.parse(localStorage.getItem("user_data"));
  const initialsessionToken = localStorage.getItem("session_token");
  const [token, setToken] = useState(initialToken);
  const [sessiontoken, setSessionToken] = useState(initialsessionToken);
  const [profiletype, setProfiletype] = useState();
  const [typeUser] = useState(initialTypeUser);
  const userIsLoggedIn = !!token;
  const [userData, setUserData] = useState(initialUserData);
  const initialExp = localStorage.getItem("expiry");
  const [expiry, setExpiry] = useState(initialExp);

  const loginHandler = (tokenL, sessionToken) => {
    const decode = jwtDecode(tokenL);
    setToken(tokenL);
    setSessionToken(sessionToken);
    localStorage.setItem("token", tokenL);
    localStorage.setItem("session_token", sessionToken);
    localStorage.setItem("user_data", JSON.stringify(decode));
    localStorage.setItem("expiry", decode?.exp);
    setUserData(decode);
    setExpiry(decode?.exp);
    window.dataLayer.push({
      event: "login",
      user_id: decode?.id,
      user_email: decode?.email,
      user_email_hash: md5(decode?.email),
    });
  };
  const profileHandler = (idprofile) => {
    setProfiletype(idprofile);
    localStorage.setItem("idprofile", idprofile);
  };
  const handleUserData = (uData) => {
    setUserData(uData);
    localStorage.setItem("user_data", JSON.stringify(uData));
  };

  const logoutHandler = () => {
    localStorage.clear();
    setToken(null);
    setSessionToken(null);
    setUserData(null);
  };

  useEffect(() => {
    if (Date.now() < expiry) {
      logoutHandler();
    }
  }, []);

  const contextValue = {
    token,
    typeUser,
    isLoggedIn: userIsLoggedIn,
    login: loginHandler,
    setProfile: profileHandler,
    logout: logoutHandler,
    profiletype,
    sessiontoken,
    userData,
    expiry,
    handleUserData,
  };

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};
export default AuthContext;
