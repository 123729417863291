import { useState, useCallback, useContext } from "react";
import AuthContext from "store/auth-context";

const useHttp = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { token, logout } = useContext(AuthContext);

  const sendRequest = useCallback(
    async ({ url, methodH, headersH, bodyH, manageData = () => {} }) => {
      setIsLoading(true);
      setError(null);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_ENDPOINT}${url}`, {
          method: methodH || "GET",
          headers: headersH || {
            "content-type": "application/json",
            accept: "application/json",
            ...(token && { Authorization: token }),
          },
          body: JSON.stringify(bodyH) || null,
        });
        if (!response.ok || response?.status === 502) {
          const temp = await response.json();
          if (temp.error === "Unauthorized" && temp.message === "Invalid token") {
            logout();
            window.location.href = "/";
          }
          throw new Error(temp.error, " Error thrown from useHttp Hook");
        }

        const data = await response.json();
        manageData(data);
        setIsLoading(false);
      } catch (err) {
        setError(err);
        setIsLoading(false);
      }
    },
    [token]
  );

  return { isLoading, error, sendRequest };
};
export default useHttp;
