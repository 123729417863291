/**
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "App";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { IntlProviderWrapper } from "store/intl-context";
import { ParallaxProvider } from "react-scroll-parallax";
import WrapperHelmet from "components/WrapperHelmet/WrapperHelmet";
import Loader from "components/ComponentsNSP/Loader";
import { CartContextProvider } from "./store/cart-context";
import { AuthContextProvider } from "./store/auth-context";

Sentry.init({
  dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
  integrations: [new BrowserTracing()],
  debug: false,
  environment: `${process.env.REACT_APP_SENTRY_ENV}`,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.2,
});

// remove class if dashboard
// document.documentElement.classList.add('container-md', 'p-0', 'px-md-3');

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={<Loader />}>
      <WrapperHelmet>
        <AuthContextProvider>
          <CartContextProvider>
            <IntlProviderWrapper>
              <ParallaxProvider>
                <App />
              </ParallaxProvider>
            </IntlProviderWrapper>
          </CartContextProvider>
        </AuthContextProvider>
      </WrapperHelmet>
    </Suspense>
  </BrowserRouter>,
  document.getElementById("root")
);
