import { useEffect } from "react";
// import ImgEmailConfirm from "assets/images/nsp/mailconfirm.svg";
import { useNavigate } from "react-router-dom";

// import BgWavyOrange from "assets/images/nsp/bg/bg-wavy-orange.svg";

const EmailConfirm = () => {
  const navigate = useNavigate();
  // const closeHendler = () => {
  //   navigate("/dashboard");
  // };

  useEffect(() => {
    navigate("/dashboard");
  }, []);
  return (
    <>
      <div className="modal-log-user min-vh-100 position-relative">
        {/* <div className="row z-index-2 justify-content-center min-vh-100">
          <div className="row d-flex flex-wrap justify-content-center g-0">
            <div className="col-12 p-0 z-index-1 mx-auto p-auto">
              <div className="window-utente w-100 mx-auto mx-md-auto text-center my-5 p-4">
                <div className="row">
                  <div className="col-12  fs-6 mt-5">
                    Email confermata! Stai per essere reindirizzato nella tua area personale
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
  // return (
  //   <>
  //     <div className="modal-log-user min-vh-100 position-relative">
  //       <div className="position-absolute z-index-n2 top-50">
  //         <img className="linea" src={BgWavyOrange} loading="lazy" alt="" />
  //       </div>
  //       <div className="row z-index-2 justify-content-center min-vh-100">
  //         <div className="row d-flex flex-wrap justify-content-center g-0">
  //           <div className="col-12 p-0 z-index-1 mx-auto p-auto">
  //             <div className="window-utente w-100 mx-auto mx-md-auto text-center my-5 p-4">
  //               <div className="imgLarge m-auto">
  //                 <img src={ImgEmailConfirm} loading="lazy" alt="" />
  //               </div>
  //               <div className="row">
  //                 <div className="col-12  fs-6 mt-5">
  //                   Ora puoi chiudere questa scheda e continuare con l’inserimento della tua pratica
  //                   <div className="text-center mt-4">
  //                     <button type="submit" className="btn" onClick={() => closeHendler()}>
  //                       Chiudi
  //                     </button>
  //                   </div>
  //                 </div>
  //               </div>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // );
};
export default EmailConfirm;
