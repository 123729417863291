import LoadingWW from "assets/images/nsp/Loadingww.gif";

export default function Loader() {
  return (
    <div className="w-100 h-100 m-auto d-flex align-items-center justify-content-center flex-column">
      <figure
        style={{
          margin: "4% auto",
        }}
      >
        <img
          style={{ width: "100px" }}
          className="m-auto d-block"
          src={LoadingWW}
          loading="lazy"
          alt=""
        />
      </figure>
      <h3 className="text-black text-center mb-2">Stiamo elaborando la tua richiesta…</h3>
    </div>
  );
}
